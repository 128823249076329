<script setup>
const items = ref([{
    label: 'Test the REST endpoint',
    color: "primary",
    class: "text-lg utab-btn",
    variant: "solid",
    defaultOpen: true,
    slot: 'swagger'
}, {
    label: 'Learn how this pipeline works',
    color: "primary",
    class: "text-lg utab-btn",
    variant: "solid",
    defaultOpen: false,
    slot: "information"
}])
</script>

<template>
    <div>
        <div class="flex justify-between items-center flex-col md:flex-row  mt-4 my-8">
            <div>
                <div class="flex gap-4 self-end">
                    <a href="https://navalgo.sharepoint.com/:f:/s/ConnectorSandbox/EgBe-VQr9h1IuR7VBeXsRfIBuOYhv-8z02_6zf4uTH8WbQ?e=YmlA05"
                        target="_blank"
                        class="flex items-center gap-4 p-4 bg-white rounded-lg border-2 border-white hover:border-slate-300 transition">
                        <Icon name="mdi:microsoft-sharepoint" class="sharepoint w-10 h-10" />
                        <div class="flex gap-2">
                            <span class="text-primary-500">
                                <Icon name="octicon:upload-16" /> Upload
                            </span>
                        </div>
                    </a>
                    <a href="https://drive.google.com/drive/u/0/folders/1cULDv2OaViJBmOfG5WB0oWcgayNrGtVs"
                        target="_blank"
                        class="flex items-center gap-4 p-4 bg-white rounded-lg border-2 border-white hover:border-slate-300 transition">
                        <Icon name="logos:google-drive" class="w-10 h-10" />
                        <div class="flex gap-2">
                            <span class="text-primary-500">
                                <Icon name="octicon:upload-16" /> Upload
                            </span>
                        </div>
                    </a>
                </div>
                <div class="flex flex-col text-sm mt-4">
                    <span><span class="font-semibold">DEMO: Uploaded files will be visible to the public.</span></span>
                    <span>Data processing is subject to our <a href="https://pathway.com/privacy_gdpr_di#privacy-policy"
                            class="text-primary-500 underline">privacy
                            policy</a></span>
                </div>
            </div>

            <div class="mb-auto">
                <a href="https://chat-realtime-sharepoint-gdrive.demo.pathway.com/" target="_blank"
                    class="bg-white block w-full p-4 flex gap-4 items-center border-2 hover:border-slate-300 transition rounded-se-lg rounded-es-lg border-primary-500">
                    <div>
                        <img src="/cloud/pathwaychat-icon.svg" alt="" class="w-10 h-10" />
                    </div>
                    <div>
                        <span class="font-semibold">Use endpoint in a chatbot</span>
                        <div class="flex gap-2">
                            <img src="/cloud/streamlit-logo.svg" alt="" class="w-[5rem]" />
                            <span>+</span>
                            <img src="/cloud/llama-logo.svg" alt="" class="w-[5rem]" />
                        </div>
                    </div>
                </a>
            </div>
        </div>

        <div class="swagger-content">

            <UAccordion :items="items" :ui="{ wrapper: 'flex flex-col w-full gap-4' }">
                <template #default="{ item, index, open }">
                    <UButton color="gray" variant="ghost"
                        class="border-b text-base border-gray-200 bg-primary-500 text-white hover:text-white hover:bg-primary-600 rounded-xl"
                        :ui="{ rounded: 'rounded-none', padding: { sm: 'p-3' } }">

                        <span class="truncate flex gap-2" v-if="item.slot === 'swagger'">{{ item.label }} <span
                                class="text-xs p-1 px-3 bg-primary-800 border border-primary-400 rounded-full mr-2 block">for
                                developers</span></span>
                        <span class="truncate" v-else>{{ item.label }}</span>

                        <template #trailing>
                            <UIcon name="heroicons:chevron-right-20-solid"
                                class="w-5 h-5 ms-auto transform transition-transform duration-200"
                                :class="[open && 'rotate-90']" />
                        </template>
                    </UButton>
                </template>
                <template #information>
                    <div class="flex flex-col text-[#232a34] text-base">
                        <span class="text-xl font-bold">Realtime Document Indexing with Pathway</span>
                        <p>This is a basic service for a real-time document indexing pipeline powered by <a
                                href="https://github.com/pathwaycom/pathway">Pathway</a>.</p>
                        <p class="mt-4">The capabilities of the service include:</p>
                        <ul class="mt-2 list-disc pl-4">
                            <li>Real-time document indexing from Microsoft 365 SharePoint</li>
                            <li>Real-time document indexing from Google Drive</li>
                            <li>Similarity search by user query</li>
                            <li>Filtering by the metadata according to the condition given in <a
                                    href="https://jmespath.org/">JMESPath</a>
                                format</li>
                            <li>Basic stats on the indexer's health</li>
                        </ul>
                        <p class="mt-4">Supported document formats include plaintext, pdf, docx, and HTML. For the
                            complete
                            list, please
                            refer to the
                            supported formats of the <a
                                href="https://github.com/Unstructured-IO/unstructured">unstructured</a> library. In
                            addition, this pipeline is capable of data removals: you can delete files and in a few
                            seconds,
                            a similarity
                            search will undo the changes done to the index by their addition.</p>
                        <p class="mt-4">Please also keep in mind the following constraints and limitations:</p>
                        <ul class="mt-2 list-disc pl-4">
                            <li>The maximum supported file size is 4 MB and 100 Kb of the plaintext is obtained after
                                parsing. Anything of
                                the
                                greater size will be ignored by the indexer</li>
                            <li>The files in the shared spaces are removed within 15 minutes after their addition</li>
                            <li>You hold responsibility for the contents of the files you upload</li>
                        </ul>
                        <div class="mt-8">
                            <a href="https://github.com/pathwaycom/llm-app/tree/main/examples/pipelines/demo-question-answering"
                                class="font-medium flex items-center gap-2 underline hover:text-primary-800 transition max-w-max text-blue-950">
                                <Icon name="mdi:github" /> See the source code
                            </a>
                        </div>
                    </div>
                </template>
                <template #swagger>
                    <LazyCloudSwaggerUI swaggerUrl="https://demo-document-indexing.pathway.stream/_schema"
                        uid="swagger-pathway-api" />
                </template>
            </UAccordion>
        </div>

    </div>
</template>

<style scoped>
.swagger-content {
    padding-bottom: 5rem;
}

.sharepoint {
    color: #2F7CC2;
}

.underline {
    text-decoration: underline;
}

.button {
    background-color: #dc280b !important;
    border: none;
    border-radius: 0.5em;
    color: #fff !important;
    cursor: pointer;
    font-weight: 700;
    padding: 0.5em 1em;
    transition: background-color .4s, color .4s;
    white-space: nowrap;
    text-decoration: none;
    font-family: sans-serif;
    font-size: 1.125rem;
    line-height: 1.75rem;
    transition: all 0.3s;
    display: inline;
}

.button:hover,
.button:focus {
    background-color: #eee;
    color: #dc280b;
}
</style>
